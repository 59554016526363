<template>
  <v-container class="my-16">
    <h2 class="accent--text">Team</h2>
    <v-divider class="py-3"></v-divider>
    <v-row width="100%">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="3"
        v-for="employee in employees"
        :key="employee.id"
      >
        <TeamMember
          @member_clicked="
            (value) => {
              dialog = !dialog;
              selectedEmployee = value;
            }
          "
          :member="employee"
        />
      </v-col>
    </v-row>
    <TeamMemberDialog
      v-if="dialog"
      :member="selectedEmployee"
      :dialog="dialog"
      @popupClosed="dialog = !dialog"
    />
  </v-container>
</template>

<script>
import TeamMember from "@/components/TeamMember";
import TeamMemberDialog from "@/components/TeamMemberDialog";
export default {
  data() {
    return {
      selectedEmployee: {},
      dialog: false,
      employees: [
        {
          id: 1,
          name: "Annika Haagsman",
          img: "https://weu-az-web-nl-cdnep.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/profiles%20new/profile_2_annika_haagsman.jpg?ext=.jpg",
          expertise: "Dierenarts Specialist Chirurgie",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 2,
          name: "Arno Roos",
          img: "https://weu-az-web-nl-cdnep.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/hero/arno-roos_1.jpg?ext=.jpg",
          expertise: "Dierenarts Oncologie",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 3,
          name: "Birgit Seubers",
          img: "https://weu-az-web-nl-cdnep.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/profile/profile_birgit1_2.jpg?ext=.jpg",
          expertise: "Dierenarts",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 4,
          name: "Dinand Favier",
          img: "https://weu-az-web-nl-cdnep.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/profiles%20new/profile_dinand_favier.jpg?ext=.jpg",
          expertise: "Dierenarts Cardiologie en Echografie",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 5,
          name: "Dorien Willems",
          img: "https://weu-az-web-nl-cdnep.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/hero/dorien-willems.jpg?ext=.jpg",
          expertise: "Dierenarts Specialist Medische Beeldvorming Dipl. ECVDI",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 6,
          name: "Els Albada",
          img: "https://weu-az-web-nl-cdnep.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/profile/els-albada-200x200_1.jpg?ext=.jpg",
          expertise: "Dierenfysiotherapeut",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
        {
          id: 7,
          name: "Erik van der Kamp",
          img: "https://weu-az-web-nl-cdnep.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/profile/profile_erik_van_der_kamp.jpg?ext=.jpg",
          expertise: "Dierenarts Tandheelkunde",
          description:
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde illo, magnam ullam, corrupti odio quos perspiciatis obcaecati quo enim, eligendi nisi praesentium modi architecto nihil exercitationem eos. Nisi cum quas sit facere sed qui doloribus vel, repudiandae iusto veritatis cumque dignissimos itaque illum, laboriosam tenetur officia inventore optio voluptatum velit sapiente consequuntur atque odit temporibus aliquam? Modi dolorum, sit vero harum commodi numquam quidem pariatur eos accusamus ipsam veritatis nemo impedit illo libero temporibus beatae delectus corrupti eligendi, eaque odit fuga, expedita quis? Ab ratione ipsum obcaecati asperiores facere optio. Harum voluptatum sint voluptate maiores reprehenderit sit animi aliquid soluta distinctio libero, deserunt illo asperiores ratione, rerum saepe magnam quos minima est quasi, debitis enim a temporibus fugiat. Unde veniam excepturi, perspiciatis corporis laudantium minima libero deserunt assumenda! Corrupti molestias dignissimos laboriosam magnam, a facilis consequuntur minus laudantium optio illo reiciendis temporibus vero animi eum corporis praesentium tempore, quos quo voluptatum ipsam dolores quibusdam non quam! Minima ipsum debitis aperiam ex beatae dolorem voluptatum ea? Fugit eos explicabo laborum nisi accusamus, veritatis molestias cumque vel modi praesentium recusandae similique vitae qui unde dolores asperiores illo maiores! Quasi, labore deleniti quibusdam vitae mollitia eligendi esse quod omnis, modi ipsa nulla tenetur.",
        },
      ],
    };
  },
  components: {
    TeamMember,
    TeamMemberDialog,
  },
  methods: {
    setupDialog(value) {
      this.selectedEmployee = value;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>